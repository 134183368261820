.call-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
    /* background-color: #212121; */
    font-family: 'Arial', sans-serif;
    color: #fff;
  
  }
  
  .join-call,
  .calling,
  .incoming-call {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .call-button,
  .cancel-button,
  .end-call-button,
  .accept-button,
  .reject-button,
  .control-button {
    background-color: #007bff;
    color: white;
    padding: 12px 25px;
    border-radius: 30px;
    font-size: 18px;
    margin: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .call-button:hover,
  .cancel-button:hover,
  .end-call-button:hover,
  .accept-button:hover,
  .reject-button:hover,
  .control-button:hover {
    background-color: #0056b3;
  }
  
  .end-call-button {
    background-color: #ff4d4d;
  }
  
  .end-call-button:hover {
    background-color: #cc0000;
  }
  
  .accept-button {
    background-color: #28a745;
  }
  
  .accept-button:hover {
    background-color: #218838;
  }
  
  .reject-button {
    background-color: #dc3545;
  }
  
  .reject-button:hover {
    background-color: #c82333;
  }
  
  .profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Video container for ongoing call */
  .video-container {
    position: relative;
    width: 100%;
    /* background-color: #1b1b1beb; */
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
    border-radius: 10px;
    /* border: 1px dotted grey; */
    
  
  }
  
  .local-video {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 300px;
    height: auto;
    border: 2px solid #007bff;
    border-radius: 10px;
  
  }
  
  .remote-video {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
  }
  
  .controls {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
  }
  
  .control-button {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 50%;
    font-size: 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .control-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .incoming-call .action-buttons {
    display: flex;
    gap: 15px;
  }
  
  @media (max-width: 768px) {
    .local-video {
      width: 200px;
      height: auto;
    }
  
    h2 {
      font-size: 20px;
    }
  
    .call-button,
    .cancel-button,
    .end-call-button,
    .accept-button,
    .reject-button,
    .control-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  